<template>
    <div class="banner-pages" v-bind:style="{backgroundImage:`url(${pathUrl.url})`}">
        <h1>{{pathUrl.title}}</h1>
        <p>{{pathUrl.description}}</p>
    </div>
</template>
<script>
import mixins from '@/mixins/index'
import { BANNERS_CONTENT } from '@/constants/banners'
import { mapState } from 'vuex'
export default {
    name: 'BannerPages',
    mixins: [mixins],
    data() {
    },
    setup() {
    },
    created() {
    },
    computed: {
        ...mapState('auth', ['user']),
        pathUrl() {
            let model;
            if (this.$route.path) {
                let currentname = BANNERS_CONTENT.find((x) => this.$route.path.includes(x.path)) || BANNERS_CONTENT[0]
                let titleFormat = currentname.title;
                if (currentname.transformdata) {
                    const { key, data } = currentname.transformdata;
                    titleFormat = titleFormat.replace(key, this.user[data])
                }
                model = {
                    url: `/assets/img/banners/${currentname.name}`,
                    title: titleFormat,
                    description: currentname.description
                }

            }

            return model
        },
    },
    methods: {
    }

}
</script>