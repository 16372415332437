const auth = {
  namespaced: true,
  state: {
    user: {
      name: "",
      lastName: "",
      email: "",
      accessToken: "",
      credits: 0,
      rfc: "",
      wantInvoice: false,
      isCreditAccount: false,
    },
    admin: false,
    logged: false,
    errors: [],
  },
  mutations: {
    LOGIN(state, user) {
      state.user.name = user.name;
      state.user.lastName = user.lastName;
      state.user.email = user.email;
      state.user.credits = user.credits;
      state.user.rfc = user.rfc;
      state.user.wantInvoice = user.facturaUID != null;
      state.user.isCreditAccount = user.isCreditAccount;
    },
    SET_TOKEN(state, token) {
      state.user.accessToken = token;
    },
    IS_ADMIN(state, admin) {
      state.admin = admin;
    },
    LOGGED(state, boolean) {
      state.logged = boolean;
    },
    LOGOUT(state) {
      console.log("logout");
      localStorage.removeItem("ahiva");
      state.user.name = "";
      state.user.lastName = "";
      state.user.email = "";
      state.user.accessToken = "";
      state.user.credits = 0;
      state.user.rfc = "";
      state.user.wantInvoice = false;
      state.user.isCreditAccount = false;
      state.admin = false;
      state.logged = false;
      state.errors = [];
    },
    UPDATE_CREDITS(state, credits) {
      state.user.credits = credits;
    },
    UPDATE_INFO(state, user) {
      state.user.name = user.name;
      state.user.lastName = user.lastName;
      state.user.email = user.email;
      state.user.credits = user.credits;
      state.user.rfc = user.rfc;
      state.user.wantInvoice = user.facturaUID != null;
    },
    SET_SERVICE_ERROR(state, errors) {
      state.errors = errors ? [{ ...errors }] : [];
    },
    CLEAR_SERVICE_ERROR(state) {
      let data = JSON.parse(localStorage.getItem("ahiva"));
      localStorage.removeItem("ahiva");
      data["errors"] = [];

      localStorage.setItem("ahiva", JSON.stringify(data));
      state.errors = [];
    },
  },
  actions: {
    login({ commit }, payload) {
      commit("LOGIN", payload.user);
      commit("SET_TOKEN", payload.token);
      commit("IS_ADMIN", payload.admin);
      commit("LOGGED", true);
    },
    logOut({ commit }, payload) {
      commit("LOGOUT", payload);
    },
    updateCredits({ commit }, credits) {
      commit("UPDATE_CREDITS", credits);
    },
    updateUserInfo({ commit }, payload) {
      commit("UPDATE_INFO", payload);
    },
    setServiceError({ commit }, payload) {
      commit("SET_SERVICE_ERROR", payload);
    },
    clearServiceError({ commit }) {
      commit("CLEAR_SERVICE_ERROR");
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    userCredits(state) {
      return state.user.credits;
    },
    isAdmin(state) {
      return state.admin;
    },
    logged(state) {
      let ahiva = JSON.parse(localStorage.getItem("ahiva"));
      return state.logged && ahiva ? true : false;
    },
    token(state) {
      return state.user.accessToken;
    },
    serviceErrors(state) {
      return state.errors;
    },
    isCreditAccount(state) {
      return state.user.isCreditAccount;
    },
  },
};
export default auth;
