<template>
  <nav class="app-nav navbar ahiva-navbar navbar-light bg-light">
    <div class="container-fluid">
      <span class="navbar-brand">{{ title }}</span>
      <div class="d-flex">
        <div v-if="!isAdmin" class="d-flex customer-items">
          <p class="amount" v-if="!isCreditAccount">
            <unicon name="money-bill" fill="#fff" />
            Saldo: <a>{{ this.numeralFormat(userCredits, '$0,0.00') }} MXN</a>
          </p>
          <p class="amount" v-if="isCreditAccount && !needPay">
            <unicon name="money-bill" fill="#fff" />
            Crédito disponible:  <a>{{ this.numeralFormat(getRestAmount, '$0,0.00') }} MXN</a>
          </p>
          <b-alert :show="needPay && isCreditAccount" :variant="daysToPay >0?'warning':'danger'">
            <small>
              <b>Tu cuenta de crédito necesita un abono</b>
              El pago necesario a loquidar es de:<b>{{ this.numeralFormat(getAmountTopay, '$0,0.00') }} MXN</b>
              Fecha limite de págo ({{ getLimitDate }}).
              <router-link :to="{ name: 'WalletView' }">
                <b>Haciendo clic aquí</b>
              </router-link>
            </small>
          </b-alert>

          <b-button :to="{ name: 'QuoteShipmentView' }" variant="outline-primary" class="mt-2">
            <unicon name="ahiva-bolt-menu" fill="#fff" height="20" width="20" viewBox="0 0 50 50" />
            Cotizador rápido
          </b-button>
          <b-button :to="{ name: 'NewShipmentForm' }" variant="primary-green" class="mt-2">
            <unicon name="plus" fill="#fff" height="19" width="19" />
            Nuevo envío
          </b-button>
          <!-- <b-button variant="danger" @click="handleLogOut">Cerrar Session</b-button> -->
        </div>
        <b-dropdown class="profile-menu" size="lg" variant="link" no-caret>
          <template #button-content>
            <b-avatar variant="info" :text="getUserNameToIcon(user.name)" class="m-auto"></b-avatar>
          </template>
          <b-dropdown-item>
            <b-avatar variant="info" :text="getUserNameToIcon(user.name)" class="m-auto"></b-avatar>
          </b-dropdown-item>
          <b-dropdown-text>
            <p class="user-email">{{ user.email }}</p>
          </b-dropdown-text>
          <!-- <b-dropdown-divider></b-dropdown-divider> -->
          <b-dropdown-item :to="{ name: 'ProfileView' }">Editar mi perfil</b-dropdown-item>
          <b-dropdown-item @click="handleLogOut">Cerrar sesión</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import mixins from '@/mixins/index'

export default {
  name: 'AppNav',
  mixins: [mixins],

  created() {
    console.log(this.$route.meta.title)
  },

  computed: {
    ...mapGetters('auth', ['user', 'isAdmin', 'userCredits', 'isCreditAccount']),
    ...mapGetters('creditAccount', ['getAmountTopay', 'needPay', 'daysToPay','getlimitAmount','getLimitDate','getRestAmount']),
    title() {
      return this.$route.meta.title
    },
  },
  methods: {
    ...mapActions({
      logOut: 'auth/logOut',
    }),
    handleLogOut() {
      this.logOut(true).then(() => {
        this.$router.push('/')
      })
    },
    getUserNameToIcon(userName) {
      let full_name = userName.split(" ");
      let initials = full_name[0][0];
      if (full_name.length >= 2 && full_name[1]) {
        initials += full_name[1][0];
      }
      return initials ? initials : full_name[0]
    }
  }
}
</script>
