import { createStore } from "vuex";
import VuexPersist from "vuex-persist";
import auth from "@/store/auth";
import shipment from "@/store/shipment";
import creditAccount from "@/store/creditAccount";

const vuexLocal = new VuexPersist({
  key: "ahiva",
  storage: window.localStorage,
});

export default createStore({
  modules: { auth, shipment, creditAccount },
  plugins: [vuexLocal.plugin],
});
