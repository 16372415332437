import httpClient from "@/api/httpClient";

export const createUser = async (body) => {
  return await httpClient.post("/user/create", body);
};

export const updateUser = async (user) => {
  return await httpClient.put(`/user/update`, user);
};

export const getUserInfo = async () => {
  return await httpClient.get(`/user/info`);
};

export const getUsersAdmin = async (id) => {
  if (!id) return await httpClient.get("/admin-user/list");
  return await httpClient.get(`/admin-user/detail/${id}`);
};

export const getPromotionalCode = async () => {
  return await httpClient.get("/user/promotionalcode");
};
