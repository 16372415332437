import { interceptor } from '@/utils/interceptor'
import axios from 'axios'

const URI = process.env.VUE_APP_URI_API

const httpClient = axios.create({
  baseURL: URI,
  timeout: 60 * 10 * 1000,
})

httpClient.defaults.insecureHTTPParser = true

httpClient.interceptors = interceptor(httpClient)

export default httpClient
