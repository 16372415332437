<template>
  <div>
    <!-- add credits -->
    <div class="add-credits text-center text-white pb-4 px-4">
      <!-- <span>
        Saldo actual:
        <br />
        ${{ userCredits }} MXN
      </span> -->
      <b-button v-if="!isCreditAccount" :to="{ name: 'WalletView' }" variant="primary-green" class="mt-2">
        + Abonar saldo
      </b-button>
    </div>
    <!-- add credits -->
    <!-- Nav Item - Dashboard -->
    <li class="nav-item" :class="active(['DashboardView'])">
      <router-link class="nav-link cursor-pointer" :to="{ name: 'DashboardView' }">
        <unicon name="ahiva-home-menu" fill="#fff" height="20" width="20" viewBox="0 0 50 50" />
        <span>Inicio</span>
      </router-link>
    </li>
    <!-- Nav Item - QuoteShipmentView -->
    <li class="nav-item" :class="active([
        'QuoteShipmentView',
        'QuoteShipmentForm',
        'QuoteShipmentOverview',
      ])
        ">
      <router-link class="nav-link cursor-pointer" :to="{ name: 'QuoteShipmentView' }">
        <unicon name="ahiva-bolt-menu" fill="#fff" height="20" width="20" viewBox="0 0 50 50" />
        <span>Cotizador rápido</span>
      </router-link>
    </li>
    <!-- Nav Item - NewShipmentView -->
    <li class="nav-item" :class="active(['NewShipmentForm', 'NewShipmentOverview'])">
      <router-link class="nav-link cursor-pointer" :to="{ name: 'NewShipmentForm' }">
        <unicon name="plus" fill="#fff" height="19" width="19" />
        <span>Nuevo envío</span>
      </router-link>
    </li>
    <!-- Nav Item - MultiGuie -->
    <li class="nav-item" :class="active(['MultiGuieView'])">
      <router-link class="nav-link cursor-pointer" :to="{ name: 'MultiGuieView' }">
        <unicon name="ahiva-multiguie-menu" fill="#fff" height="20" width="20" viewBox="0 0 50 50" />
        <span>Multiguía</span>
      </router-link>
    </li>
    <!-- Nav Item - PickupView -->
    <li class="nav-item" :class="active(['PickupList', 'PickupSelect', 'PickupCreate'])">
      <router-link class="nav-link cursor-pointer" :to="{ name: 'PickupView' }">
        <unicon name="ahiva-recolection-menu" fill="#fff" height="20" width="20" viewBox="0 0 50 50" />
        <span>Recolecciones</span>
      </router-link>
    </li>
    <!-- Nav Item - ShipmentHistoryView -->
    <li class="nav-item" :class="active(['ShipmentHistoryView'])">
      <router-link class="nav-link cursor-pointer" :to="{ name: 'ShipmentHistoryView' }">
        <unicon name="ahiva-shipment-history-menu" fill="#fff" height="20" width="20" viewBox="0 0 50 50" />
        <span>Historial de envíos</span>
      </router-link>
    </li>
    <li class="nav-item" :class="active(['NewOrders'])">
      <router-link class="nav-link cursor-pointer" :to="{ name: 'OrdersView' }">
        <unicon name="ahiva-orders-menu" fill="#fff" height="20" width="20" viewBox="0 0 50 50" />
        <span>Órdenes</span>
      </router-link>
    </li>
    <!-- Nav Item - MovementsView -->
    <li class="nav-item" :class="active(['MovementsView'])">
      <router-link class="nav-link cursor-pointer" :to="{ name: 'MovementsView' }">
        <unicon name="dollar-alt" fill="#fff" height="19" width="19" />
        <span>Movimientos</span>
      </router-link>
    </li>
    <!-- Nav Item -   InvoicesView -->
    <!-- set acordition-->
    <!-- <li class="nav-item" :class="active(['InvoiceList', 'TaxInformation'])">
      <router-link class="nav-link cursor-pointer" :to="{ name: 'InvoicesView' }">
        <unicon name="invoice" fill="#fff" height="20" width="20" />
        <span>Facturación</span>
      </router-link>
    </li> -->
    <li class="nav-item" :class="active(['ConnectionStores', 'FailConnection', 'SuccessConnection'])
        ">
      <router-link class="nav-link cursor-pointer" :to="{ name: 'StoresConnectionView' }">
        <unicon name="ahiva-stores_connection-menu" fill="#fff" height="20" width="20" viewBox="0 0 50 50" />
        <span>Conectar tienda</span>
      </router-link>
    </li>
    <!-- Nav Item - OverweightView -->
    <li class="nav-item" :class="active(['OverweightView'])">
      <router-link class="nav-link cursor-pointer" :to="{ name: 'OverweightView' }">
        <unicon name="weight" fill="#fff" height="18" width="18" />
        <span>Sobrepesos</span>
      </router-link>
    </li>

    <li class="nav-item acordion" :class="isToggleSpanded('collapse-directions') != '' ? null : 'collapsed'"
      v-b-toggle:collapse-directions :aria-expanded="isToggleSpanded('collapse-directions') != ''"
      aria-controls="collapse-directions" @click="setToggleSpand('collapse-directions')">
      <div class="nav-link cursor-pointer" :class="isToggleSpanded('collapse-directions')">
        <unicon name="ahiva-directions-menu" fill="#fff" height="20" width="20" viewBox="0 0 50 50" />
        <span>Mis direcciones</span>
      </div>
    </li>
    <b-collapse id="collapse-directions" class="collapse-container"
      :v-model="isToggleSpanded('collapse-directions') != ''">
      <!-- <b-card></b-card> -->
      <div class="items-content">
        <!-- Nav Item - SendersView -->
        <li class="nav-item" :class="active(['SendersList', 'SendersForm', 'SenderFormEdit'])">
          <router-link class="nav-link cursor-pointer" :to="{ name: 'SendersView' }">
            <unicon name="users-alt" fill="#fff" height="18" width="18" />
            <span>Remitentes</span>
          </router-link>
        </li>
        <!-- Nav Item - RecipientsView -->
        <li class="nav-item" :class="active(['RecipientsList', 'RecipientsForm', 'RecipientsFormEdit'])">
          <router-link class="nav-link cursor-pointer" :to="{ name: 'RecipientsView' }">
            <unicon name="users-alt" fill="#fff" height="18" width="18" />
            <span>Destinatarios</span>
          </router-link>
        </li>
      </div>
    </b-collapse>

    <li class="nav-item acordion" :class="isToggleSpanded('collapse-config') != '' ? null : 'collapsed'"
      v-b-toggle:collapse-config :aria-expanded="isToggleSpanded('collapse-config') != ''" aria-controls="collapse-config"
      @click="setToggleSpand('collapse-config')">
      <div class="nav-link cursor-pointer" :class="isToggleSpanded('collapse-config')">
        <unicon name="ahiva-configuration-menu" fill="#fff" height="20" width="20" viewBox="0 0 50 50" />
        <span>Configuración</span>
      </div>
    </li>
    <b-collapse id="collapse-config" class="collapse-container" :v-model="isToggleSpanded('collapse-config') != ''">
      <!-- <b-card></b-card> -->
      <div class="items-content">
        <li class="nav-item" :class="active(['InvoiceList'])">
          <router-link class="nav-link cursor-pointer" :to="{ name: 'InvoicesView' }">
            <unicon name="ahiva-billing-menu" fill="#fff" height="20" width="20" viewBox="0 0 50 50" />
            <span>Facturación</span>
          </router-link>
        </li>
        <li class="nav-item" :class="active(['TaxInformation'])">
          <router-link class="nav-link cursor-pointer" :to="{ name: 'TaxInformation' }">
            <unicon name="ahiva-billing-menu" fill="#fff" height="20" width="20" viewBox="0 0 50 50" />
            <span>Datos de Facturación</span>
          </router-link>
        </li>
        <li class="nav-item" :class="active(['ProfileView'])">
          <router-link class="nav-link cursor-pointer" :to="{ name: 'ProfileView' }">
            <unicon name="ahiva-profile-menu" fill="#fff" height="20" width="20" viewBox="0 0 50 50" />
            <span>Perfil</span>
          </router-link>
        </li>
      </div>
    </b-collapse>
    <li class="nav-item" :class="active(['SuportView'])">
      <a class="nav-link cursor-pointer" href="https://www.youtube.com/@ahivaenvios" target="_blank">
        <unicon name="comment-alt-question" fill="#fff" height="18" width="18" />
        <span>Soporte</span>
      </a>
    </li>

    <!-- Nav Item - StorageView -->
    <!-- <li class="nav-item" :class="active(['StorageView'])">
      <router-link class="nav-link cursor-pointer" :to="{ name: 'StorageView' }">
        <unicon name="box" fill="#fff" height="20" width="20" />
        <span>Almacenaje</span>
      </router-link>
    </li> -->
    <!-- Nav Item - InventoryView -->
    <!-- <li
      class="nav-item"
      :class="active(['InventoryList', 'InventoryForm', 'InventoryOverview'])"
    >
      <router-link class="nav-link cursor-pointer" :to="{ name: 'InventoryView' }">
        <unicon name="file-info-alt" fill="#fff" height="20" width="20" />
        <span>Inventario</span>
      </router-link>
    </li> -->
    <!-- Nav Item - HelpView -->
    <!-- <li class="nav-item" :class="active(['HelpView'])">
      <router-link class="nav-link cursor-pointer" :to="{ name: 'HelpView' }">
        <unicon name="question-circle" fill="#fff" height="20" width="20" />
        <span>Ayuda</span>
      </router-link>
    </li> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserMenu',

  data() {
    return {
      toggleSpand: []
    }
  },
  methods: {
    active(views) {
      return views.includes(this.page) ? 'active' : ''
    },
    isToggleSpanded(id) {
      return this.toggleSpand.includes(id) ? 'active-collapse' : ''
    },
    setToggleSpand(id) {

      if (this.toggleSpand.includes(id)) {
        this.toggleSpand = this.toggleSpand.filter(x => x !== id);
        return
      }

      this.toggleSpand.push(id);
    }
  },

  computed: {
    ...mapGetters('auth', ['userCredits', 'isCreditAccount']),

    page() {
      return this.$route.name
    },
  },
}
</script>

<style lang="scss">
.cursor-pointer {
  cursor: pointer !important;
}
</style>
