const creditAccount = {
  namespaced: true,
  state: {
    credit: {
      dateOfpay: 0,
      status: "",
      amount: 0,
      paidAt: null,
      daysToPay: 0,
      userId: null,
      limitAmount:0,
      limitDateToPay:null,
      restAmount:0
    },
  },
  mutations: {
    LOADCREDIT(state, credit) {
      state.credit.dateOfpay = credit.dateOfpay;
      state.credit.status = credit.status;
      state.credit.amount = credit.amount;
      state.credit.paidAt = credit.paidAt;
      state.credit.daysToPay = credit.daysToPay;
      state.credit.userId = credit.daysToPay;
      state.credit.limitAmount = credit.limitAmount;
      state.credit.limitDateToPay = credit.limitDateToPay;
      state.credit.restAmount = credit.restAmount;
    },
    REMOVOECREDIT(state) {
      state.credit.dateOfpay = 0;
      state.credit.status = "";
      state.credit.amount = 0;
      state.credit.paidAt = null;
      state.credit.daysToPay = 0;
      state.credit.userId = null;
      state.credit.limitAmount=0
      state.credit.limitDateToPay = null;
      state.credit.restAmount = 0;
    },
    UPDATE_CREDIT_ACCOUNT(state, credit) {
      state.credit = credit;
    },
  },
  actions: {
    loadCredit({ commit }, payload) {
      commit("LOADCREDIT", payload.credit);
    },
    removeCredit({ commit }, payload) {
      commit("REMOVOECREDIT", payload);
    },
    updateCreditAccount({ commit }, credits) {
      commit("UPDATE_CREDIT_ACCOUNT", credits);
    },
  },
  getters: {
    daysToPay(state) {
      return state.credit.daysToPay;
    },
    needPay(state) {
      return state.credit.status === "outstading";
    },
    getAmountTopay(state) {
      // return Math.ceil(state.credit.amount);
      return parseFloat(state.credit.amount);
    },
    user(state) {
      return state.user;
    },
    getLimitDate(state){
      const date = new Date(state.credit.limitDateToPay)
      return ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()
    }, 
    getlimitAmount(state){
      return parseFloat(state.credit.limitAmount)
    },
    getRestAmount(state){
      return parseFloat(state.credit.restAmount)
    }
  },
};
export default creditAccount;
