<template>
  <div id="wrapper" class="dashboard-layout">
    <AutoLogout />
    <SideBar></SideBar>
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content" class="layout">
        <AppNav></AppNav>
        <div class="container-fluid content-container">
          <BannerPages></BannerPages>
          <router-view class="container-fluid content-router page h-100 p-3" :key="$route.path" />
          <AppFooter></AppFooter>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AutoLogout from '@/components/AutoLogout'
import SideBar from '@/components/SideBar'
import AppNav from '@/components/AppNav'
import AppFooter from '@/components/AppFooter'
import BannerPages from '@/shared/bannerPages/BannerPages'
import mixins from '@/mixins/index'
import { mapState } from 'vuex'

export default {
  name: 'DashboardLayout',

  mixins: [mixins],

  components: {
    AutoLogout,
    SideBar,
    AppNav,
    AppFooter,
    BannerPages
  },

  computed: {
    ...mapState('auth', ['user']),
  },
}
</script>

<style lang="scss">
.dashboard-layout {
  .page {
    background-color: #fff;
  }
}
</style>
