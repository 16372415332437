<template>
  <div class="not-found">
    404 Not Found
  </div>
</template>

<script>
export default {
  name: 'NotFoundView',
}
</script>
