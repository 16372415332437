<template>
  <div class="sidebar">
    <!-- Sidebar -->
    <router-link
      class="sidebar-brand d-flex align-items-center justify-content-center"
      :to="{ name: 'DashboardView' }"
    >
      <div class="sidebar-brand-icon rotate-n-15">
        <i class="fas fa-laugh-wink"></i>
      </div>
      <div class="sidebar-brand-text mx-3">
        <img src="@/assets/img/brand/logo.png" class="img-fluid" />
      </div>
    </router-link>
    <ul class="navbar-nav sidebar sidebar-dark accordion" id="accordionSidebar">
      <!-- Sidebar - Brand -->
      <!-- Divider -->
      <hr class="sidebar-divider my-0" />
      <div class="sidebar-content">
        <AdminMenu v-if="isAdmin" />
        <UserMenu v-else />
      </div>
      <!-- Divider -->
      <!-- <hr class="sidebar-divider" />
      <div class="text-center d-none d-md-inline">
        <button class="rounded-circle border-0" id="sidebarToggle"></button>
      </div> -->
    </ul>
    <div class="text-center m-auto end-btn">
      <b-button variant="link-primary" class="mt-2 d-flex m-auto" @click="handleLogOut">
        <unicon name="ahiva-sign-out-menu" class="m-auto" fill="#fff" height="20" width="20"  viewBox="0 0 50 50"/>
        <p class="m-auto">Cerrar sesión</p>
      </b-button>
    </div>
  </div>
</template>

<script>
import UserMenu from '@/components/nav/UserMenu'
import AdminMenu from '@/components/nav/AdminMenu'
import { mapGetters,mapActions } from 'vuex'

export default {
  name: 'SideBar',

  components: { UserMenu, AdminMenu },

  computed: {
    ...mapGetters('auth', ['isAdmin','isCreditAccount','serviceErrors']),
  },
  methods:{
    ...mapActions({
      logOut: 'auth/logOut',
    }),
    handleLogOut() {
      this.logOut(true).then(() => {
        this.$router.push('/')
      })
    }
  }
}
</script>

<style lang="scss">
hr.sidebar-divider {
  border-top: 1px solid rgba(255, 255, 255, 1) !important;
}
</style>
