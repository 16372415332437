import { getCreditByUser } from "@/services/creditsService";
import { getUserInfo } from "@/services/userService";
import { mapActions } from "vuex";
import store from "@/store";
import { EmailRegEx, PhoneRegEx } from "@/constants/regexConstants";

export default {
  methods: {
    ...mapActions({
      updateCredits: "auth/updateCredits",
      updateUserInfo: "auth/updateUserInfo",
      setNewShipment: "shipment/setNewShipment",
      setQuoteShipment: "shipment/setQuoteShipment",
      setPickup: "shipment/setPickup",
      loadCredit: "creditAccount/loadCredit",
      logged: "auth/logged",
    }),
    showAlert(data, status) {
      this.$swal({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 9000,
        timerProgressBar: true,
        icon: status,
        title: data,
      });
    },
    creditsAlert() {
      this.$swal({
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-primary",
        },
        buttonsStyling: false,
        html: `
          <h3>Saldo Insuficiente</h3>
          <span>No cuentas con saldo suficiente para cotizar
          tu envío. ¿Te gustaría agregar saldo?</span>
        `,
        showCancelButton: true,
        cancelButtonText: `No, gracias`,
        confirmButtonText: "Si quiero abonar",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push({ name: "WalletView" });
        }
      });
    },
    needPayAlert() {
      this.$swal({
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-primary",
        },
        buttonsStyling: false,
        html: `
          <h3>Pago necesario</h3>
          <span>No has saldado a tu cuenta para poder cotizar
          tu envío. ¿Te gustaría agregar saldo?</span>
        `,
        showCancelButton: true,
        cancelButtonText: `No, gracias`,
        confirmButtonText: "Si quiero abonar",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push({ name: "WalletView" });
        }
      });
    },
    minimumPakageAlert() {
      this.$swal({
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-primary",
        },
        buttonsStyling: false,
        html: `
          <h3>Medidas del paquete necesitan un cambio</h3>
          <span>Las medias minimas para un paquete son Alto = 10cm, Ancho = 10cm, Largo = 10cm </span>
        `,
        showCancelButton: false,
        // cancelButtonText: `No, gracias`,
        confirmButtonText: "Si quiero abonar",
        reverseButtons: true,
      });
    },
    ValidateEmail(email) {
      if (EmailRegEx.test(`${email}`)) {
        return true;
      }
      return false;
    },
    ValidatePhone(phone){
      if(PhoneRegEx.test(`${phone}`)){
        return true;
      } 
      return false;
    },
    NumbersOnly(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    textLength(text, min, max) {
      const length = text.length;
      if (length == 0) return null;
      return length >= min && length <= max;
    },
    imageTool(img) {
      return require("@/assets/img/" + img);
    },
    eye(type) {
      return type == "password" ? "eye-slash" : "eye";
    },
    showPassword(n) {
      if (n == 0) {
        const pass = this.type_password;
        this.type_password = pass == "password" ? "text" : "password";
      } else if (n == 1) {
        const pass = this.type_confirm_password;
        this.type_confirm_password = pass == "password" ? "text" : "password";
      } else {
        const pass = this.type_current_password;
        this.type_current_password = pass == "password" ? "text" : "password";
      }
    },
    statusName(value) {
      let status = "";
      switch (value) {
        case "complete":
          status = "Completo";
          break;
        case "pending":
          status = "Pendiente";
          break;
        case "error":
          status = "Error";
          break;
        case "refund":
          status = "Reembolso";
          break;
        case "canceled":
          status = "Cancelado";
          break;
        default:
          status = "---";
          break;
      }
      return status;
    },
    localeDate(value) {
      let date = new Date(value);
      date.setDate(date.getDate());
      let fllwDate = date.toLocaleDateString("es-MX", {
        year: "numeric",
        month: "short",
        day: "numeric",
        // hour: "numeric",
        // minute: "numeric",
      });
      return fllwDate;
    },
    credits() {
      getUserInfo().then((res) => {
        if (res.data.success) {
          this.updateCredits(res.data.user.credits);
          if (res.data.user.isCreditAccount) {
            getCreditByUser({ uid: res.data.user.id }).then(({ data }) => {
              console.log("CreditData", data.credit);
              this.loadCredit({
                credit: data.credit,
              });
            });
          }
        }
      });
    },
    updateUser() {
      getUserInfo().then((res) => {
        if (res.data.success) {
          this.updateUserInfo(res.data.user);
        }
      });
    },
    addressFormat(address) {
      return {
        id: address.id,
        status: `${address.active ? "Activo" : "Inactivo"}`,
        fullName: `${address.name} ${address.lastName}`,
        ...(address?.company ? { company: address.company } : {}),
        rfc: address.rfc,
        email: address.email,
        phone: address.phone,
        address: `${address.street} #${address.numExt}, ${address.suburb}, cp.${address.zipCode}`,
        city: address.city,
        state: `${address.state}, ${address.country}`,
        reference: address.reference,
      };
    },
    rateName(value) {
      let rate = "";
      switch (value) {
        case 0:
          rate = "Tarifa Básica";
          break;
        case 1:
          rate = "Tarifa Estándar";
          break;
        case 2:
          rate = "Tarifa Premium";
          break;
      }
      return rate;
    },
    deleteProps(obj, prop) {
      for (const p of prop) {
        p in obj && delete obj[p];
      }
    },
    getValueMessageObj(obj) {
      if (typeof obj === "object") {
        Object.keys(obj).map((key) => {
          if (typeof obj[key] === "object") {
            return this.getValueMessageObj(obj[key]);
          } else {
            return obj[key];
          }
        });
      } else {
        return obj;
      }
    },
    inputTexOnlyNumbers(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isLogged() {
      let loggedIn = store.getters["auth/logged"];
      return loggedIn;
    },
  },
};
