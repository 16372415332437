<template>
  <div id="wrapper" class="dashboard-layout">
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content" class="layout">
        <div class="container-fluid content-container-external external">
          <BannerPages></BannerPages>
          <router-view class="container-fluid content-router page h-100 p-3" :key="$route.path" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BannerPages from '@/shared/bannerPages/BannerPages'
import mixins from '@/mixins/index'

export default {
  name: 'ExternalPagesLayout',

  mixins: [mixins],
  components: {
    BannerPages
  },
}
</script>

<style lang="scss">
.dashboard-layout {
  overflow-x: hidden !important;
  .page {
    background-color: #fff;
  }
}
</style>
