import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

import LoginLayout from "@/components/layouts/LoginLayout";
import DashboardLayout from "@/components/layouts/DashboardLayout";
import NotFoundLayout from "@/components/layouts/NotFoundLayout";
import ExternalPagesLayout from "@/components/layouts/ExternalPagesLayout";

const routes = [
  {
    path: "/",
    name: "LoginView",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/LoginView.vue"),
    meta: { layout: LoginLayout, requiresAuth: false },
  },
  {
    path: "/register",
    name: "RegisterView",
    component: () =>
      import(/* webpackChunkName: "register" */ "@/views/RegisterView.vue"),
    meta: { layout: LoginLayout, requiresAuth: false },
  },
  {
    path: "/forgot-password",
    name: "ForgotPasswordView",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "@/views/ForgotPasswordView.vue"
      ),
    meta: { layout: LoginLayout, requiresAuth: false },
  },
  {
    path: "/reset-password/:resetToken?",
    name: "ResetPasswordView",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "@/views/ResetPasswordView.vue"
      ),
    meta: { layout: LoginLayout, requiresAuth: false },
  },
  {
    path: "/inicio",
    name: "DashboardView",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/DashboardView.vue"),
    meta: {
      requiresAuth: true,
      layout: DashboardLayout,
      title: "Inicio",
    },
  },
  {
    path: "/perfil",
    name: "ProfileView",
    component: () =>
      import(/* webpackChunkName: "profile" */ "@/views/ProfileView.vue"),
    meta: {
      requiresAuth: true,
      layout: DashboardLayout,
      title: "Perfil",
    },
  },
  {
    path: "/ordenes/",
    name: "OrdersView",
    component: () =>
      import(/* webpackChunkName: "orders" */ "@/views/OrdersView.vue"),
    meta: {
      requiresAuth: true,
      layout: DashboardLayout,
      title: "Órdenes",
    },
    children: [
      {
        path: "nuevas-ordenes",
        name: "NewOrders",
        component: () =>
          import(
            /* webpackChunkName: "new-orders" */ "@/components/orders/Orders.vue"
          ),
      },
      // {
      //   path: "detalle/:id",
      //   name: "NewOrderOverview",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "user" */ "@/components/orders/OrderDetail.vue"
      //     ),
      // },
      // {
      //   path: "gracias/:id",
      //   name: "ShipmentThankYou",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "user" */ "@/components/shipment/ShipmentThankYou.vue"
      //     ),
      // },
    ],
  },
  {
    path: "/nuevo-envio/",
    name: "NewShipmentView",
    component: () =>
      import(
        /* webpackChunkName: "new-shipment" */ "@/views/NewShipmentView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: DashboardLayout,
      title: "Nuevo envío",
    },
    children: [
      {
        path: "nuevo/:provider?/:orderId?/:urlStore?",
        name: "NewShipmentForm",
        component: () =>
          import(
            /* webpackChunkName: "new-shipment-form" */ "@/components/shipment/NewShipmentForm.vue"
          ),
      },
      {
        path: "resumen/:provider?/:orderId?/:storeOrderId?/:urlStore?",
        name: "NewShipmentOverview",
        component: () =>
          import(
            /* webpackChunkName: "new-shipment-overview" */ "@/components/shipment/NewShipmentOverview.vue"
          ),
      },
      {
        path: "gracias/:id",
        name: "ShipmentThankYou",
        component: () =>
          import(
            /* webpackChunkName: "shipment-thank-you" */ "@/components/shipment/ShipmentThankYou.vue"
          ),
      },
    ],
  },
  {
    path: "/multi-guia",
    name: "MultiGuieView",
    component: () =>
      import(
        /* webpackChunkName: "multiguide" */ "@/components/multiguide/MultiGuide.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: DashboardLayout,
      title: "Multi Guia",
    },
  },
  {
    path: "/historial-de-envios",
    name: "ShipmentHistoryView",
    component: () =>
      import(
        /* webpackChunkName: "shipment-history" */ "@/views/ShipmentHistoryView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: DashboardLayout,
      title: "Historial de envíos",
    },
  },
  {
    path: "/envios-cancelados",
    name: "AdminShipmentHistoryView",
    component: () =>
      import(
        /* webpackChunkName: "shipment-history" */ "@/views/AdminShipmentHistoryView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: DashboardLayout,
      title: "Envíos Cancelados",
    },
  },
  {
    path: "/recolecciones/",
    name: "PickupView",
    component: () =>
      import(/* webpackChunkName: "pickups" */ "@/views/PickupView.vue"),
    meta: {
      requiresAuth: true,
      layout: DashboardLayout,
      title: "Recolecciones",
    },
    children: [
      {
        path: "lista",
        name: "PickupList",
        component: () =>
          import(
            /* webpackChunkName: "pickup-list" */ "@/components/pickup/PickupList.vue"
          ),
      },
      {
        path: "nueva",
        name: "PickupSelect",
        component: () =>
          import(
            /* webpackChunkName: "pickup-select" */ "@/components/pickup/PickupSelect.vue"
          ),
      },
      {
        path: "programar",
        name: "PickupCreate",
        component: () =>
          import(
            /* webpackChunkName: "pickup-create" */ "@/components/pickup/PickupCreate.vue"
          ),
      },
      {
        path: "gracias/:id",
        name: "PickupThankYou",
        component: () =>
          import(
            /* webpackChunkName: "pickup-thank-you" */ "@/components/pickup/PickupThankYou.vue"
          ),
      },
    ],
  },
  {
    path: "/integraciones/",
    name: "StoresConnectionView",
    component: () =>
      import(
        /* webpackChunkName: "stores" */ "@/views/StoresConnectionView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: DashboardLayout,
      title: "Conectar Tienda",
    },
    children: [
      {
        path: "conectar_tienda",
        name: "ConnectionStores",
        component: () =>
          import(
            /* webpackChunkName: "store-connection" */ "@/components/storeConnection/ConnectionStores.vue"
          ),
      },
      {
        path: "conexion_exitosa",
        name: "SuccessConnection",
        component: () =>
          import(
            /* webpackChunkName: "store-success-connection" */ "@/components/storeConnection/SuccessConnection.vue"
          ),
      },
      {
        path: "conexion_fallida",
        name: "FailConnection",
        component: () =>
          import(
            /* webpackChunkName: "store-fail-connection" */ "@/components/storeConnection/FailConnection.vue"
          ),
      },
    ],
  },
  {
    path: "/cotizar-envio",
    name: "QuoteShipmentView",
    component: () =>
      import(/* webpackChunkName: "quotes" */ "@/views/QuoteShipmentView.vue"),
    meta: {
      requiresAuth: true,
      layout: DashboardLayout,
      title: "Cotizador rápido",
    },
    children: [
      {
        path: "nuevo",
        name: "QuoteShipmentForm",
        component: () =>
          import(
            /* webpackChunkName: "quote-shipment-form" */ "@/components/quote/QuoteShipmentForm.vue"
          ),
      },
      {
        path: "resumen",
        name: "QuoteShipmentOverview",
        component: () =>
          import(
            /* webpackChunkName: "quote-shipment-overview" */ "@/components/quote/QuoteShipmentOverview.vue"
          ),
      },
    ],
  },
  {
    path: "/movimientos",
    name: "MovementsView",
    component: () =>
      import(/* webpackChunkName: "movements" */ "@/views/MovementsView.vue"),
    meta: {
      requiresAuth: true,
      layout: DashboardLayout,
      title: "Movimientos",
    },
  },
  {
    path: "/facturacion",
    name: "InvoicesView",
    component: () =>
      import(/* webpackChunkName: "invoices" */ "@/views/InvoicesView.vue"),
    meta: {
      requiresAuth: true,
      layout: DashboardLayout,
      title: "Facturación",
    },
    children: [
      {
        path: "lista",
        name: "InvoiceList",
        component: () =>
          import(
            /* webpackChunkName: "invoice-list" */ "@/components/invoice/InvoiceList.vue"
          ),
      },
      {
        path: "datos-fiscales",
        name: "TaxInformation",
        component: () =>
          import(
            /* webpackChunkName: "tax-information" */ "@/components/invoice/TaxInformation.vue"
          ),
      },
    ],
  },
  {
    path: "/facturas",
    name: "InvoicesAdminView",
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ "@/views/InvoicesAdminView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: DashboardLayout,
      title: "Facturación",
    },
    children: [
      {
        path: "lista",
        name: "InvoiceAdminList",
        component: () =>
          import(
            /* webpackChunkName: "invoice-list" */ "@/components/invoice/InvoiceAdminList.vue"
          ),
      },
    ],
  },
  {
    path: "/sobrepesos",
    name: "OverweightView",
    component: () =>
      import(
        /* webpackChunkName: "overweights" */ "@/views/OverweightView.vue"
      ),
    meta: {
      requiresAuth: true,
      layout: DashboardLayout,
      title: "Sobrepesos",
    },
  },
  {
    path: "/almacenaje",
    name: "StorageView",
    component: () =>
      import(/* webpackChunkName: "storage" */ "@/views/StorageView.vue"),
    meta: {
      requiresAuth: true,
      layout: DashboardLayout,
      title: "Almacenaje",
    },
  },
  {
    path: "/inventario/",
    name: "InventoryView",
    component: () =>
      import(/* webpackChunkName: "inventory" */ "@/views/InventoryView.vue"),
    meta: {
      requiresAuth: true,
      layout: DashboardLayout,
      title: "Inventario",
    },
    children: [
      {
        path: "lista",
        name: "InventoryList",
        component: () =>
          import(
            /* webpackChunkName: "inventory-list" */ "@/components/inventory/InventoryList.vue"
          ),
      },
      {
        path: "nuevo-envio",
        name: "InventoryForm",
        component: () =>
          import(
            /* webpackChunkName: "inventory-form" */ "@/components/inventory/InventoryForm.vue"
          ),
      },
      {
        path: "resumen",
        name: "InventoryOverview",
        component: () =>
          import(
            /* webpackChunkName: "inventory-overview" */ "@/components/inventory/InventoryOverview.vue"
          ),
      },
    ],
  },
  {
    path: "/remitentes/",
    name: "SendersView",
    component: () =>
      import(/* webpackChunkName: "senders" */ "@/views/SendersView.vue"),
    meta: {
      requiresAuth: false,
      layout: DashboardLayout,
      title: "Remitentes",
    },
    children: [
      {
        path: "lista",
        name: "SendersList",
        component: () =>
          import(
            /* webpackChunkName: "sender-list" */ "@/components/senders/SendersList.vue"
          ),
      },
      {
        path: "nuevo",
        name: "SendersForm",
        component: () =>
          import(
            /* webpackChunkName: "sender-form" */ "@/components/senders/SendersForm.vue"
          ),
      },
      {
        path: ":id?",
        name: "SenderFormEdit",
        component: () =>
          import(
            /* webpackChunkName: "sender-form" */ "@/components/senders/SendersForm.vue"
          ),
      },
    ],
  },
  {
    path: "/destinatarios/",
    name: "RecipientsView",
    component: () =>
      import(/* webpackChunkName: "recipients" */ "@/views/RecipientsView.vue"),
    meta: {
      requiresAuth: true,
      layout: DashboardLayout,
      title: "Destinatarios",
    },
    children: [
      {
        path: "lista/:addNew?",
        name: "RecipientsList",
        component: () =>
          import(
            /* webpackChunkName: "recipient-list" */ "@/components/recipients/RecipientsList.vue"
          ),
      },
      {
        path: "nuevo",
        name: "RecipientsForm",
        component: () =>
          import(
            /* webpackChunkName: "recipient-form" */ "@/components/recipients/RecipientsForm.vue"
          ),
      },
      {
        path: ":id?",
        name: "RecipientsFormEdit",
        component: () =>
          import(
            /* webpackChunkName: "recipient-form" */ "@/components/recipients/RecipientsForm.vue"
          ),
      },
      {
        path: "carga-masiva",
        name: "RecipientsUpload",
        component: () =>
          import(
            /* webpackChunkName: "recipient-upload" */ "@/components/recipients/RecipientsUpload.vue"
          ),
      },
    ],
  },
  {
    path: "/ayuda",
    name: "HelpView",
    component: () =>
      import(/* webpackChunkName: "help" */ "@/views/HelpView.vue"),
    meta: {
      requiresAuth: false,
      layout: DashboardLayout,
      title: "Ayuda",
    },
  },
  {
    path: "/cartera",
    name: "WalletView",
    component: () =>
      import(/* webpackChunkName: "wallet" */ "@/views/WalletView.vue"),
    meta: {
      requiresAuth: true,
      layout: DashboardLayout,
      title: "Ahíva Wallet",
    },
  },
  {
    path: "/usuarios",
    name: "UsersView",
    component: () =>
      import(/* webpackChunkName: "users" */ "@/views/UsersView.vue"),
    meta: {
      admin: true,
      requiresAuth: true,
      layout: DashboardLayout,
    },
  },
  {
    path: "/clientes",
    name: "CustomersView",
    component: () =>
      import(/* webpackChunkName: "customers" */ "@/views/CustomersView.vue"),
    meta: {
      admin: true,
      requiresAuth: true,
      layout: DashboardLayout,
    },
  },
  {
    path: "/comisiones/",
    name: "CommissionsView",
    component: () =>
      import(
        /* webpackChunkName: "commissions" */ "@/views/CommissionsView.vue"
      ),
    meta: {
      admin: true,
      requiresAuth: true,
      layout: DashboardLayout,
      title: "Comisiones",
    },
    children: [
      // {
      //   path: "seleccion",
      //   name: "CommissionsSelect",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "commission-select" */ "@/components/commission/CommissionsSelect.vue"
      //     ),
      // },
      {
        path: "editar/:user?",
        name: "CommissionsEdit",
        component: () =>
          import(
            /* webpackChunkName: "commission-edit" */ "@/components/commission/CommissionsEdit.vue"
          ),
      },
    ],
  },
  {
    path: "/creditos/",
    name: "CreditsView",
    component: () =>
      import(/* webpackChunkName: "CreditsView" */ "@/views/CreditsView.vue"),
    meta: {
      admin: true,
      requiresAuth: true,
      layout: DashboardLayout,
      title: "Creditos",
    },
    children: [
      {
        path: "admin",
        name: "CreditsAdmin",
        component: () =>
          import(
            /* webpackChunkName: "CreditsAdmin" */ "@/components/credits/CreditsAdmin.vue"
          ),
      },
    ],
  },

  {
    path: "/usuarios-admin/",
    name: "UserAdminView",
    component: () =>
      import(
        /* webpackChunkName: "UserAdminView" */ "@/views/UserAdminView.vue"
      ),
    meta: {
      admin: true,
      requiresAuth: true,
      layout: DashboardLayout,
      title: "Lista de Usuarios",
    },
    children: [
      {
        path: "list",
        name: "UserAdminList",
        component: () =>
          import(
            /* webpackChunkName: "new-orders" */ "@/components/usersAdmin/UsersAdmin.vue"
          ),
      },
      {
        path: "detalle/:userId",
        name: "UserAdminDetail",
        meta: {
          title: "Detalle de Usuario",
        },
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/components/usersAdmin/UserAdminDetail.vue"
          ),
      },
    ],
  },
  {
    path: "/shipping-list",
    name: "ShippingListView",
    component: () =>
      import(
        /* webpackChunkName: "shipping-list" */ "@/views/ShippingListView.vue"
      ),
    meta: {
      admin: true,
      requiresAuth: true,
      layout: DashboardLayout,
    },
  },
  {
    path: "/externo-cotizar-envio",
    name: "QuoteShipmentExternalView",
    component: () =>
      import(
        /* webpackChunkName: "quotes" */ "@/views/QuoteShipmentExternalView.vue"
      ),
    meta: {
      requiresAuth: false,
      layout: ExternalPagesLayout,
      title: "Cotizador rápido",
    },
    children: [
      {
        path: "nuevo",
        name: "QuoteShipmentExternalForm",
        component: () =>
          import(
            /* webpackChunkName: "quote-shipment-form" */ "@/components/quote/QuoteShipmentExternalForm.vue"
          ),
      },
      {
        path: "resumen",
        name: "QuoteShipmentExternalOverview",
        component: () =>
          import(
            /* webpackChunkName: "quote-shipment-overview" */ "@/components/quote/QuoteShipmentExternalOverview.vue"
          ),
      },
    ],
  },
  {
    path: "/inventory-admin",
    name: "InventoryAdminView",
    component: () =>
      import(
        /* webpackChunkName: "inventory-admin" */ "@/views/InventoryAdminView.vue"
      ),
    meta: {
      admin: true,
      requiresAuth: true,
      layout: DashboardLayout,
    },
  },
  {
    path: "/warehouse",
    name: "WarehouseView",
    component: () =>
      import(/* webpackChunkName: "warehouse" */ "@/views/WarehouseView.vue"),
    meta: {
      admin: true,
      requiresAuth: true,
      layout: DashboardLayout,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFoundView",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "@/views/NotFoundView.vue"),
    meta: { layout: NotFoundLayout },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.body.scrollIntoView({ behavior: "smooth" });
  const authRequired = to.matched.some((record) => record.meta.requiresAuth);
  let loggedIn = store.getters["auth/logged"];
  
  if ((to.name === "LoginView" || to.name === "RegisterView") && loggedIn) {
    return next({ name: "DashboardView" });
  } else if (to.name !== "LoginView" && !loggedIn && authRequired) {
    return next({ name: "LoginView" });
  } else {
    return next();
  }

  //  authRequired ? (loggedIn ? next() : next({ name: 'LoginView' })) : next()
});

export default router;
