<template>
  <component :is="this.$route.meta.layout" />
</template>
<script>
import mixins from '@/mixins/index'
import { mapGetters } from 'vuex'
export default {
  mixins: [mixins],
  watch: {
    '$route'() {
      //posible lo mueva de aqui
      if (this.isLogged()) {
        this.credits()
      } else if (!this.isLogged() && this.$route.meta.requiresAuth) {
        this.$router.push('/')
      }
    },
    logged: {
      handler(newVal) {
        if(!newVal &&  this.$route.meta.requiresAuth) {
          this.$router.push({ name: 'LoginView' })
        }
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters("auth", ['logged']),
  },
}
</script>


<style lang="scss">
@import '@/assets/scss/main.scss';
</style>
