import store from "@/store";

export const interceptor = (instance) => {
  instance.interceptors.request.use(
    (request) => {
      const token = store.getters["auth/token"] || null;

      if (
        token &&
        request["url"].indexOf("http") < 0 &&
        request["url"].indexOf("login") < 0
      ) {
        request.headers["Authorization"] = ` Bearer ${token}`;
      }
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      let errorString = error?.response?.data || error;
      if (
        error.request.responseType === "blob" &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf("json") != -1
      ) {
        errorString = JSON.parse(await error.response.data.text());
      }
      if (errorString.status === 401) {
        const { status } = errorString;
        if (status === 401) {
          store.dispatch({
            type: "auth/logOut",
            error: "",
          });
        }
      }
      store.dispatch({
        type: "auth/setServiceError",
        error: errorString,
      });
      return Promise.reject(errorString);
    }
  );

  return instance;
};
