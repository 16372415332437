import httpClient from "@/api/httpClient";

export const getCreditByUser = async({uid})=>{
  return await httpClient.get(`/credits/credit-by-user?uid=${uid}`)
}
export const getUserWithCredits = async () => {
  return await httpClient.get("/credits/users-with-credit");
};

export const getUserToAssing = async (query) => {
  return await httpClient.get(`/credits/users-to-assign?search=${query}`);
};

export const assignCredit = async (body) => {
  return await httpClient.post("/credits/assign-credit", body);
};

export const unassingCredit = async (body) => {
  return await httpClient.post("/credits/unassign-credit", body);
};

export const editCredit = async (body) => {
  return await httpClient.post("/credits/edit-credit", body);
};
