const shipment = {
  namespaced: true,
  state: {
    newShipment: {},
    quoteShipment: {},
    newPickup: {},
  },
  mutations: {
    NEW_SHIPMENT(state, shipment) {
      state.newShipment = shipment
    },
    REMOVE_SHIPMENT(state) {
      state.newShipment = {}
    },
    QUOTE_SHIPMENT(state, quote) {
      state.quoteShipment = quote
    },
    REMOVE_QUOTE_SHIPMENT(state) {
      state.quoteShipment = {}
    },
    NEW_PICKUP(state, pickup) {
      state.newPickup = pickup
    },
    REMOVE_PICKUP(state) {
      state.newPickup = {}
    },
  },
  actions: {
    setNewShipment({ commit }, payload) {
      commit('NEW_SHIPMENT', payload)
    },
    setQuoteShipment({ commit }, payload) {
      commit('QUOTE_SHIPMENT', payload)
    },
    setPickup({ commit }, payload) {
      commit('NEW_PICKUP', payload)
    },
  },
  getters: {
    pendingShipment(state) {
      return Object.keys(state.newShipment).length > 0
    },
    newShipment(state) {
      console.log(state)
      return state.newShipment
    },
    pendingQuoteShipment(state) {
      return Object.keys(state.quoteShipment).length > 0
    },
    quoteShipment(state) {
      console.log(state)
      return state.quoteShipment
    },
    pendingPickup(state) {
      return Object.keys(state.newPickup).length > 0
    },
    newPickup(state) {
      console.log(state)
      return state.newPickup
    },
  },
}
export default shipment
