export const BANNERS_CONTENT = [
  {
    name: "1BannerInicio.png",
    path: "/inicio",
    title: "¡Bienvenido {1}!",
    description:
      "Aquí encontrarás las mejores herramientas para comenzar a realizar tus envíos.",
    transformdata: {
        key:'{1}',
        data:'name'
    },

  },
  {
    name: "2BannerCotizadorRapido.png",
    path: "/cotizar-envio",
    title: "Cotiza de manera rápida tu próximo envío",
    description:
      "En esta sección podrás cotizar de manera rápida el costo de tu envío al ingresar los códigos postales de origen y destino, las medidas y peso de tu envío.",
  },
  {
    name: "2BannerCotizadorRapido.png",
    path: "/externo-cotizar-envio",
    title: "Cotiza de manera rápida tu próximo envío",
    description:
      "En esta sección podrás cotizar de manera rápida el costo de tu envío al ingresar los códigos postales de origen y destino, las medidas y peso de tu envío.",
  },
  {
    name: "3BannerNuevoEnvio.png",
    path: "/nuevo-envio",
    title: "Ingresa los datos requeridos, compara opciones y envíalo.",
    description:
      "Llena todos los campos para cotizar tu envío y podrás seleccionar la opción que más te convenga.",
  },
  {
    name: "4BannerPagos.png",
    path: "/cartera",
    title: "Abona saldo a tu cuenta y consulta tus últimos movimientos",
    description:
      "Puedes abonar saldo con tarjetas de crédito, débito y generar fichas digitales para pago en tiendas de conveniencia.",
  },
  {
    name: "5BannerMultiguia.png",
    path: "/multi-guia",
    title: "Genera solicitudes masivas de guías",
    description:
      "Esta opción es de gran utilidad si tienes una larga lista de órdenes. Importa tus órdenes en nuestro formato Excel y podrás generar múltiples guías a la vez.",
  },
  {
    name: "6BannerRecolecciones.png",
    path: "/recolecciones",
    title: "Recolecciones de paquetes",
    description:
      "Programa recolecciones de tus paquetes a domicilio. Este servicio no te genera ningún costo adicional.",
  },
  {
    name: "7BannerHistorialDeEnvios.png",
    path: "/historial-de-envios",
    title: "Historial de envíos",
    description:
      "Conoce el historial de tus envíos y los detalles de cada una de tus guías generadas.",
  },
  {
    name: "8BannerOrdenes.png",
    path: "/ordenes",
    title: "Sincroniza las órdenes de tu tienda aquí",
    description:
      "Gestiona y sincroniza las órdenes creadas en tu tienda. Cotiza y genera las guías de manera rápida y sencilla.",
  },
  {
    name: "9BannerConectarTienda.png",
    path: "/integraciones",
    title: "Conecta tu comercio electrónico con nuestra plataforma",
    description:
      "De esta manera podrás gestionar envíos con la información de los pedidos de tus clientes directamente en nuestra plataforma, optimizando tus tiempos en generación de guías.",
  },
  {
    name: "11BannerDestinatarios.png",
    path: "/destinatarios",
    title: "Registra y gestiona tu libreta de destinatarios",
    description:
      "Aquí podrás agregar, editar o eliminar tus direcciones de destino.",
  },
  {
    name: "10BannerRemitentes.png",
    path: "/remitentes",
    title: "Registra y gestiona tu libreta de remitentes",
    description:
      "Aquí podrás agregar, editar o eliminar tus direcciones de remitentes.",
  },
  {
    name: "12BannerFacturas.png",
    path: "/facturacion",
    title: "Facturación de tu cuenta",
    description:
      "Encuentra las facturas de los abonos realizados a tu cuenta. (Las facturas se generan automáticamente)",
  },
  {
    name: "1BannerInicio.png",
    path: "/movimientos",
    title: "Consulta de movimientos",
    description:
      "Aquí podrás encontrar el historial completo de tus movimientos en nuestra plataforma.",
  },{
    name: "1BannerInicio.png",
    path: "/sobrepesos",
    title: "Sobrepesos",
    description:
      "Aquí encontrarás los cargos relacionados con sobrepesos registrados en tus envíos.",
  },
  {
    name: "1BannerInicio.png",
    path: "/perfil",
    title: "Tu perfil personal",
    description:
      "Personaliza los datos de tu perfil en nuestra plataforma",
  },
];
