<template>
  <div class="app-footer">
    <div class="container-fluid">
      <b-row class="">
        <b-col md="12">
          <small>
            © {{ currentYear }} Ahíva | Todos los derechos reservados.
          </small>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',

  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
  },
}
</script>
