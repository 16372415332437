import { createApp } from "vue";
import BootstrapVue3 from "bootstrap-vue-3";
import VueSweetalert2 from "vue-sweetalert2";
import Unicon from "vue-unicons";
import {
  uniEstate,
  uniUser,
  uniUsersAlt,
  uniPlus,
  uniHistory,
  uniTruck,
  uniBill,
  uniDollarAlt,
  uniWeight,
  uniBox,
  uniFileInfoAlt,
  uniListUl,
  uniQuestionCircle,
  uniCheckCircle,
  uniExclamationTriangle,
  uniEye,
  uniEyeSlash,
  uniInvoice,
  uniCreateDashboard,
  uniClipboard,
  uniTrashAlt,
  uniMoneyBill,
  uniFileUpload,
  uniMultiply,
  uniArrowRight,
  uniMoneyStack,
  uniCommentAltQuestion,
  uniSpinner,
  uniArrowLeft,
} from "vue-unicons/dist/icons";
import { VuelidatePlugin } from "@vuelidate/core";
import SmartTable from "vuejs-smart-table";
import VueNumerals from "vue-numerals";
import { createDynamicForms } from "@asigloo/vue-dynamic-forms";

const sweetOptions = {
  confirmButtonColor: "#5cb85c",
  cancelButtonColor: "#d9534f",
};

//custom unicon icons
import { CUSTOM_ICONS } from "./constants/custom-icons";

// import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import "sweetalert2/dist/sweetalert2.min.css";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import JsonExcel from "vue-json-excel3";

const VueDynamicForms = createDynamicForms({
  // Global Options go here
});

//paginator
import VueAwesomePaginate from "vue-awesome-paginate";

// import "vue-awesome-paginate/dist/style.css";

Unicon.add([
  uniEstate,
  uniUser,
  uniUsersAlt,
  uniPlus,
  uniHistory,
  uniTruck,
  uniBill,
  uniDollarAlt,
  uniWeight,
  uniBox,
  uniFileInfoAlt,
  uniListUl,
  uniQuestionCircle,
  uniCheckCircle,
  uniExclamationTriangle,
  uniEye,
  uniEyeSlash,
  uniInvoice,
  uniCreateDashboard,
  uniClipboard,
  uniTrashAlt,
  uniMoneyBill,
  uniFileUpload,
  uniMultiply,
  uniArrowRight,
  uniMoneyStack,
  uniCommentAltQuestion,
  uniSpinner,
  uniArrowLeft,
  CUSTOM_ICONS.home_menu,
  CUSTOM_ICONS.bolt_menu,
  CUSTOM_ICONS.multiguie_menu,
  CUSTOM_ICONS.recolection_menu,
  CUSTOM_ICONS.shipment_history_menu,
  CUSTOM_ICONS.orders_menu,
  CUSTOM_ICONS.stores_connection_menu,
  CUSTOM_ICONS.directions_menu,
  CUSTOM_ICONS.configuration_menu,
  CUSTOM_ICONS.billing_menu,
  CUSTOM_ICONS.profile_menu,
  CUSTOM_ICONS.sign_out_menu,
  CUSTOM_ICONS.quote_dashboard,
  CUSTOM_ICONS.income_dashboard,
  CUSTOM_ICONS.new_guie_dashboard,
  CUSTOM_ICONS.download_excel,
]);

const app = createApp(App)
  .use(SmartTable)
  .use(BootstrapVue3)
  .use(VueSweetalert2, sweetOptions)
  .use(Unicon)
  .use(store)
  .use(router)
  .use(VuelidatePlugin)
  .use(VueNumerals)
  .use(VueDynamicForms);

app.component("downloadExcel", JsonExcel);

app.use(VueAwesomePaginate);

app.mount("#app");
