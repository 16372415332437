<template>
  <div>
    <!-- Nav Item - Dashboard -->
    <li class="nav-item" :class="active(['DashboardView'])">
      <router-link class="nav-link" :to="{ name: 'DashboardView' }">
        <unicon name="estate" fill="#fff" height="20" width="20" />
        <span>Inicio</span>
      </router-link>
    </li>
    <!-- Nav Item - Commissions -->
    <li class="nav-item" :class="active(['CommissionsView', 'CommissionsSelect', 'CommissionsEdit'])
      ">
      <router-link class="nav-link" :to="{ path: '/comisiones/editar/0' }">
        <unicon name="dollar-alt" fill="#fff" height="20" width="20" />
        <span>Comisiones</span>
      </router-link>
    </li>
    <li class="nav-item" :class="active(['AdminShipmentHistoryView'])
      ">
      <router-link class="nav-link" :to="{ name: 'AdminShipmentHistoryView' }">
        <unicon name="ahiva-billing-menu" fill="#fff" height="20" width="20" viewBox="0 0 50 50" />
        <span>Envíos Cancelados</span>
      </router-link>
    </li>
    <li class="nav-item" :class="active(['InvoicesAdminView', 'InvoiceAdminList'])
      ">
      <router-link class="nav-link" :to="{ name: 'InvoicesAdminView' }">
        <unicon name="ahiva-billing-menu" fill="#fff" height="20" width="20" viewBox="0 0 50 50" />
        <span>Facturas</span>
      </router-link>
    </li>
    <li class="nav-item" :class="active(['CreditsView', 'CreditsAdmin'])
      ">
      <router-link class="nav-link" :to="{ name: 'CreditsView' }">
        <unicon name="money-stack" fill="#fff" height="20" width="20" />
        <span>Créditos</span>
      </router-link>
    </li>
    <li class="nav-item" :class="active(['UserAdminView', 'UserAdminList', 'UserAdminDetail'])">
      <router-link class="nav-link" :to="{ name: 'UserAdminView' }">
        <unicon name="users-alt" fill="#fff" height="20" width="20" />
        <span>Usuarios</span>
      </router-link>
    </li>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AdminMenu',

  methods: {
    active(views) {
      return views.includes(this.page) ? 'active' : ''
    },
  },

  computed: {
    ...mapGetters('auth', ['credits']),

    page() {
      return this.$route.name
    },
  },
}
</script>
